import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Cta from "../components/cta"
import Seo from "../components/seo"
import TestimonialSection from "../components/testimonials-section"
import ArrowRight from "../components/svg/arrowRight"

const AWSPage = () => (
    <Layout>
        <Seo 
            title="AWS Support for Startups" 
            description="Launch Lab offers Amazon Web Services (AWS) support and web app development services to startups in Australia."
            pathname="/aws-infrastructure/"
        />

        <Hero
            h1="AWS Support for Startups"  
            h2="We assist startups with Amazon Web Services infrastructure"
        />

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                        data-sal="slide-right" 
                        data-sal-easing="ease-in-out"
                        data-sal-duration="400"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">20+</span>
                            <p>We've developed more than 20 startup applications on Amazon Web Services (AWS).</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Serverless</span>
                            <p>Our preference, where suitable, is for a serverless infrastructure for security and scalability.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">CDK</span>
                            <p>In 2021 we started using CDK (with Python) for faster backend development.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Experienced AWS developers</h3>
                        </div>

                        <div className="col-67 long-text"
                            data-sal="slide-up" 
                            data-sal-easing="ease-in-out"
                            data-sal-duration="400"
                        >
                            <p>
                                Launch Lab <Link to="/startup-web-application-development/">develops startups</Link> for non-technical founders in Australia.
                            </p>
                            <p>
                                We mainly use Amazon Web Services (AWS) for our infrastructure needs and develop most of our startups using AWS products like: 
                            </p>
                            <ul>
                                <li>CDK, with <Link to="/python-developer/">Python</Link></li>
                                <li>Lambda (serverless)</li>
                                <li>Cloudfront</li>
                                <li>S3</li>
                                <li>SES</li>
                                <li>DynamoDB</li>
                            </ul>
                            <p>
                                We've also used EC2 / Docker as well as services like RDS, API Gateway, Step Functions,  Route53, AWS IoT and more etc. We also have an active interest in AWS Bedrock and Amazon Q.
                            </p>
                            <p>
                                Once we know the technical requirements of a project we can help determine which AWS resources will be most suitable for the stage you're at. 
                            </p>
                            <p>
                                If you're looking to develop a startup we'd love to chat and discuss how we leverage the world's number 1 cloud infrastructure to get startups started.
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                    Discuss your project
                                    <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="well bg-purple">
                        <h4>AWS Activate partner</h4>
                        <p>Launch Lab is a partner in the <a href="https://aws.amazon.com/startups" target="_blank" rel="noopener noreferrer">AWS Activate</a> program. If you're an Australian startup and ready to build your startup please speak to us about our AWS services and the great discounts the AWS Activate program offers.</p>
                    </div>
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="If you need a local and experienced team to assist with your startup's AWS hosting and infrastructure needs we'd love to chat."
        />
    </Layout>
)

export default AWSPage